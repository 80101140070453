import { RedocStandalone } from "redoc";
import "./App.css";
import { LOGO_URL } from "./app-config";
const API_URL = process.env.REACT_APP_OPEN_API;

function App() {
  return (
    <>
      <div>
        <img src={LOGO_URL} alt="ELD" style={{ maxHeight: 50, padding: 10 }} />
      </div>
      <RedocStandalone specUrl={API_URL} />
    </>
  );
}

export default App;
